import React from 'react';

function DomainSale() {
  const domain = window.location.host;
  const email = 'admin#' + domain;

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center justify-center">
      <div className="w-1/2 bg-white p-6 rounded-md shadow-md">
        <h1 className="text-2xl font-bold mb-4">域名出售中</h1>
        <p className="text-gray-500 mb-2">域名: {domain}</p>
        <p className="text-gray-500 mb-2">联系方式: {email}</p>
      </div>
    </div>
  );
}

export default DomainSale;
